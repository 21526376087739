:root {
  /* --primary-color: #333333; */
  --dark-grey:#4C4C4C;
  --light-grey:#DFDFDF;
  --google-blue:#4285f4;
  --button-active-blue: #C4C4C4;
}
.auth__main{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  margin: 0px;
  height: 100vh;
  width: 100vw;
}
.auth__box-container{
  background-color: white;
  box-shadow: 0px 3px var(--dark-grey);
  border-radius: 2px;
  padding: 20px;
  width: 250px;
}
.auth__title{
  /* color: darken($color: var(--primary-color), $amount: 20); */
  color: var(--primary-color);
  margin-bottom: 10px;
}
.auth__input{
  color: var(--dark-grey);
  border: 0px;
  border-bottom: 1px solid var(--light-grey);
  font-size: 16px;
  margin-bottom: 10px;
  height: 20px;
  width: 100%;
  transition: border-bottom .3s ease;
}
.auth__input:focus{
  border-bottom: 1px solid var(--primary-color);
    outline: none;
}
.auth__social-networks{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
}
.auth__alert-error{
  background-color: red;
  border-radius: 5px;
  color: white;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  padding: 5px;
}
/* Buttons */
.btn{
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 13px;
  padding: 7px 10px 7px 10px;
  transition: color .3s ease;
}
.btn:focus{
 outline: none;
}
.btn:hover{
    /* color: darken($color: white, $amount: 10); */
    color: #e5e5e5;
}
.btn-primary{
  background-color: var(--primary-color);
  border-radius: 2px;
}
.btn-primary:disabled{
 /* background-color: lighten($color: $primary, $amount: 15); */
 background-color: #7479cd;
}
.btn-primary:hover{
/* background-color: darken($color: $primary, $amount: 15); */
background-color: var(--dark-grey);
}

.btn-danger{
  background-color: red;
  transition: background-color .3s ease;
}
.btn-danger:hover{
   /* background-color: darken($color: red, $amount: 10); */
   background-color: #e50000;;
}

.btn-block{
  width: 100%;
}

.google-btn {
    cursor: pointer;
    margin-top: 5px;
    width: 100%;
    height: 42px;
    background-color: var(--google-blue);
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);

    transition: box-shadow .3s ease;

   
}
.google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: white;
}
.google-icon {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
}
.btn-text {
  float: right;
  margin: 11px 10px 0 0;
  
  color: white;
  font-size: 14px;
  letter-spacing: 0.2px;
}
.google-btn:hover {
  box-shadow: 0 0 6px var(--google-blue);
}
.google-btn:active {
  background: var(--button-active-blue);
}
/* Links */
.link{
  color: var(--dark-grey);
  text-decoration: none;
}
.link:hover{
  text-decoration: underline;
}
.btn-facebook{
  text-transform: none;
  background-color: #3A5795;
  color: white;
  padding: 10px;
  border: 1px solid #3A5795;
  /* font-family: 'lucida grande', tahoma, verdana, arial, sans-serif; */
  font-family: Helvetica, Arial, 'lucida grande',tahoma,verdana,arial,sans-serif;
  border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);

    transition: box-shadow .3s ease;
   
}
.btn-facebook:hover{
  cursor: pointer;
  box-shadow: 0 0 6px var(--google-blue);
}