.comment{
  margin-top: 20px;
}
.comments-title{
  font-size: 30px;
  margin-bottom: 20px;
}
.comments-container{
  margin-top: 40px;
}
.comment-form-title{
  font-size: 22px;
}
.comment-form-textarea{
  width: 100%;
  height: 80px;
  margin-bottom: 8px;
  margin-top: 8px;
  border: 1px solid rgb(107,114,12);
}
.comment-form-button {
  font-size: 16px;
  padding: 8px 16px;
  background: var(--alternative-color);
  border-radius: 8px;
  color: var(--primary-color);
}

.comment-form-button:hover:enabled {
  cursor: pointer;
  background: var(--accent-color);
  color: var(--white-color);
}

.comment-form-button:disabled {
  opacity: 0.7;
  cursor: default;
}

.comment-form-cancel-button {
  margin-left: 10px;
}

.comment {
  display: flex;
  margin-bottom: 28px;
}

.comment-image-container {
  margin-right: 12px;
}

.comment-image-container img {
  border-radius: 50px;
}

.comment-right-part {
  width: 100%;
}

.comment-content {
  display: flex;
}

.comment-author {
  margin-right: 8px;
  font-size: 20px;
  color: var(--accent-color)
}

.comment-text {
  font-size: 18px;
}

.comment-actions {
  display: flex;
  font-size: 12px;
  color: rgb(51, 51, 51);
  
  margin-top: 8px;
  width: auto;
}

.comment-action {
  margin-right: 8px;cursor: pointer;
}

.comment-action:hover {
  text-decoration: underline;
}

.replies {
  margin-top: 20px;
}