body {
  /* margin-top: 65px;
  margin-left: 20px;
  margin-right: 20px; */
}

.sidebar {
  grid-area: sidebar;
}

.content {
  grid-area: content;
  position: relative;
  height: 60vh;
}

.header {
  grid-area: header;
}

.footer {
  grid-area: footer;
}

.wrapper {
		display: grid;
    grid-gap: 10px;
		grid-template-columns: repeat(2,1fr);
		grid-template-areas:
    /* "header  header  header" */
		"content content sidebar"
		"footer  footer  footer";
		background-color: #fff;
		color: #444;
	}


.box {
  background-color: #444;
  color: #fff;
  border-radius: 5px;
  padding: 50px;
  font-size: 150%;
}

.header,
.footer {
  background-color: #999;
}

.topleft {
  position: absolute;
  top: 0;
  left: 0;
}

.topright {
  position: absolute;
  top: 0;
  right: 0;
}

.bottomleft {
  position: absolute;
  bottom: 0;
  left: 0;
}

.bottomright {
  position: absolute;
  bottom: 0;
  right: 0;
}

/* Animacion de logo  */

#e8NPb91vf6I6 {
  animation: e8NPb91vf6I6_c_o 5000ms linear 1 normal forwards
}

@keyframes e8NPb91vf6I6_c_o {
  0% {
      opacity: 0
  }

  30% {
      opacity: 1
  }

  100% {
      opacity: 1
  }
}

#e8NPb91vf6I29_ts {
  animation: e8NPb91vf6I29_ts__ts 3000ms linear 1 normal forwards
}

@keyframes e8NPb91vf6I29_ts__ts {
  0% {
      transform: translate(-1395.928492px, 1750.55216px) scale(0.040089, 0.040089)
  }

  33.333333% {
      transform: translate(-1395.928492px, 1750.55216px) scale(0.040089, 0.040089);
      animation-timing-function: cubic-bezier(0.42, 0, 1, 1)
  }

  66.666667% {
      transform: translate(-1395.928492px, 1750.55216px) scale(0.993287, 0.993287)
  }

  100% {
      transform: translate(-1395.928492px, 1750.55216px) scale(0.993287, 0.993287)
  }
}

#e8NPb91vf6I29 {
  animation: e8NPb91vf6I29_c_o 3000ms linear 1 normal forwards
}

@keyframes e8NPb91vf6I29_c_o {
  0% {
      opacity: 0
  }

  33.333333% {
      opacity: 0
  }

  53.333333% {
      opacity: 1
  }

  100% {
      opacity: 1
  }
}

#e8NPb91vf6I42_to {
  animation: e8NPb91vf6I42_to__to 3000ms linear 1 normal forwards
}

@keyframes e8NPb91vf6I42_to__to {
  0% {
      transform: translate(-1395.637512px, 1610.467095px)
  }

  56.666667% {
      transform: translate(-1395.637512px, 1610.467095px)
  }

  73.333333% {
      transform: translate(-1395.637512px, 1581.692328px)
  }

  100% {
      transform: translate(-1395.637512px, 1581.692328px)
  }
}

#e8NPb91vf6I42_ts {
  animation: e8NPb91vf6I42_ts__ts 3000ms linear 1 normal forwards
}

@keyframes e8NPb91vf6I42_ts__ts {
  0% {
      transform: scale(0.144347, 0.144347)
  }

  56.666667% {
      transform: scale(0.144347, 0.144347)
  }

  73.333333% {
      transform: scale(1.000019, 1.000019)
  }

  100% {
      transform: scale(1.000019, 1.000019)
  }
}

#e8NPb91vf6I42 {
  animation: e8NPb91vf6I42_c_o 3000ms linear 1 normal forwards
}

@keyframes e8NPb91vf6I42_c_o {
  0% {
      opacity: 0
  }

  56.666667% {
      opacity: 0
  }

  73.333333% {
      opacity: 1
  }

  100% {
      opacity: 1
  }
}