.chip-all:hover{
 border-radius: 20px; 
 background-color: var(--primary-color);
 border: 1px solid var(--primary-color);
 color: var(--white-color);
}
.chip-all{
  border-radius: 20px; 
 background-color: var(--white-color);
 border: 1px solid var(--white-color);
  padding: 5px;
  transition: ease .2s;
}