.banner{
  /* background: #5cb85c; */
    box-shadow: inset 0 8px 8px -8px rgb(0 0 0 / 30%), inset 0 -8px 8px -8px rgb(0 0 0 / 30%);
    color: #fff;
    background: var(--white-color);
    padding: 4rem 2rem;
    /* margin-bottom: 2rem; */
    /* margin-top: 50px; */
      /* padding-top: 20px;
  padding-bottom: 20px; */
}
.container{
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;

}
.banner h1{
  text-shadow: 0 1px 3px rgb(0 0 0 / 30%);
    font-weight: 700!important;
    text-align: center;
    font-size: 3.5rem;
    padding-bottom: 0.5rem;
}
.banner h2{
  color: var(--primary-color);
    text-align: center;
    font-size: 1.6rem;
    font-weight: 300!important;
    margin-bottom: 0;
}