

.tags-input-container{
  border: 1px solid #C4C4C4;
  padding: .5em;
  border-radius: 3px;
  width: 100%;
  margin: 1em 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: .5em;
}

.tag-item{
  background-color: rgb(218, 216, 216);
  display: inline-block;
  padding: .5em .75em;
  border-radius: 20px;
}
.tags-input-container:focus,.tags-input-container:active,.tags-input-container:focus-within{
  /* border: 2px solid #1976D2 !important; */
  outline-width: 2px;
  outline-style: auto;
  outline-color: #1976D2;
}
.tags-input-container:hover{
  border: 1px solid #000;
}
.tag-item .close{
  height: 20px;
  width: 20px;
  background-color: rgb(48, 48, 48);
  color: #fff;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: .5em;
  font-size: 18px;
  cursor: pointer;
}

.tags-input{
  flex-grow: 1;
  padding: .5em 0;
  border: none;
  outline: none;
}
.disabled-div{
  pointer-events: none;
  opacity: 0.7;
}