:root {
  /* --main-bg-color: brown; */
  --circleSize: 165px;
  --radius: 100px;
  --shadow: 0 0 10px 0 rgba(255,255,255,.35);
  --fontColor: rgb(250,250,250);
}
.profile-page .user-info {
  text-align: center;
  background: #f3f3f3;
  padding: 2rem 0 1rem;
}

@media (min-width: 992px){
.container-profile {
    max-width: 940px;
}
}
@media (min-width: 768px){
  .container-profile {
    max-width: 720px;
}
}
@media (min-width: 544px){
  .container-profile {
    max-width: 576px;
}
}
.container-profile {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.profile-page .user-info {
  text-align: center;
  background: #f3f3f3;
  padding: 2rem 0 1rem;
}

.profile-page .user-info .user-img {
  width: 150px;
  height: 150px;
  border-radius: 100px;
  margin-bottom: 1rem;
}

img {
  vertical-align: middle;
}
/* img {
  border: 0;
} */
.profile-pic {
  width: var(--circleSize);
  height: var(--circleSize);
  color: transparent;
  transition: all .3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all .3s ease;
}

.profile-pic input {
  display: none;
}
.profile-pic img {
  position: absolute;
    object-fit: cover;
    width: var(--circleSize);
    height: var(--circleSize);
    box-shadow: var(--shadow);
    border-radius: var(--radius);
    z-index: 0;
}
/* .-label{
  display: inline;
} */
/* .-label:hover{
  display: block;
} */
.profile-pic .-label {
  cursor: pointer;
    height: var(--circleSize);
    width: var(--circleSize);
}
.profile-pic:hover .-label {
  position: absolute;
  background-color: rgba(0,0,0,.5);
  z-index: 10000;
  
  color: var(--fontColor);
  transition: background-color .2s ease-in-out;
  border-radius: var(--radius);
}
.profile-pic span{
  display: inline-flex;
  padding: .2em;
  height: 2em;
  margin-top: 70px;
  text-align: center;
}
.container-campos{
  display: flex;
 
  flex-direction: column;
  align-items: center;
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input{
  font-size: 1.5rem !important;
  font-family: Arial;
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input{
  font-size: 1.5rem !important;
  font-family: Arial;
}
.css-4orime-MuiButtonBase-root-MuiButton-root:hover{
  color: var(--primary-color) !important;
}
.css-10tr04p-MuiButtonBase-root-MuiButton-root:hover{
  color: var(--primary-color) !important;
  
}
.actions-nota-btn:hover{
  color: var(--primary-color);
}
/* .css-7dd05q:hover{
  color: var(--primary-color) !important;
} */
/* .css-7dd05q:hover{
  color: var(--primary-color) !important;
} */