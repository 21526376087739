.text-cuted{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
          line-clamp: 2; 
  -webkit-box-orient: vertical;
}
.preview-a{
  text-decoration: none;
/* border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);

    transition: box-shadow .3s ease; */
  }
.preview-a:hover{
cursor: pointer;
text-decoration: underline;
text-decoration-color: var(--primary-color);
/* box-shadow: 0 0 6px var(--primary-color); */

}

.card-link-preview{
  display: flex;
  flex-direction: row;
  
}
.card-link-preview:hover{


}

@media (max-width: 500px){
/* .imagen-link-preview{
  display: none;
} */
.card-link-preview{
  display: flex;
  flex-direction: column;
  
}
.imagen-link-preview{
  width: auto;
}

}