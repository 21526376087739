:root{
--primary-color:#081d4b;
--secondary-color:#DFDFDF;  
--alternative-color:#C4C4C4;
--white-color:#FFFFFF;
--rojo-color:#E1706C;
--amarillo-color:#E8B54D;
--verde-color:#7BCC96;
--accent-color:#4C4C4C;
}
.font-face-cg {
  font-family: "CenturyGothic";
 }
 .font-face-arial{
  font-family: Arial;
 }
 body{
  margin: 0px;
 }