.options-del-nav-bar{

  padding: 10px;
  color: #fff;
  font-family: Arial;
  text-decoration: none;
  
}
.options-del-nav-bar:hover{
  text-decoration: none;
  color: var(--alternative-color);
}
.optionsNavbar{
  display:flex;
  flex-direction:row;
  gap:10px;
}
.top{
  
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  background-color:#081d4b;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-left: 30px;
  margin-right: 30px;
  /* margin-top: 5px;
  margin-bottom: 5px; */
}