.blog-post-hero{
  width: 100%;
  height: 400px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 20px;
}
.usuario:hover{
  cursor: pointer;
}
.nota-container{
  max-width: 740px;
    margin: 0 auto;
    padding: 0 15px;
}
.notaContent{
  margin-bottom: 30px;
  --x-height-multiplier: 0.35;
  --baseline-multiplier: 0.179;
  letter-spacing: .01rem;
  font-weight: 400;
  font-style: normal;
  font-family: 'Arial';
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -.003em;
}
.actions{
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.actions-nota{
  display: flex;
  justify-content: space-around;
  gap: 15px;
}
.actions-nota-btn:hover{
  color: var(--primary-color);
}
.actions-nota-btn:hover > .button-action{
  color: var(--primary-color);
  /* border-color: var(--primary-color); */
}
.css-fqefhi-MuiButtonBase-root-MuiButton-root:hover > .button-action{
  color: var(--primary-color) !important;
}
.css-1k85h0v-MuiButtonBase-root-MuiButton-root:hover{
  color: var(--primary-color) !important;
}
.css-1k85h0v-MuiButtonBase-root-MuiButton-root:hover > .button-action{
  color: var(--primary-color) !important;
}
/* .button-action:hover{
  color: var(--primary-color)
} */