.text-cuted-content-article {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
          line-clamp: 2; 
  -webkit-box-orient: vertical;
}
.card-nota{
  /* display: flex;
  flex-direction: column; */
  
}
.card-nota-int{
  box-shadow: 3px 7px 21px -8px rgba(8,29,75,0.47) !important;
-webkit-box-shadow: 3px 7px 21px -8px rgba(8,29,75,0.47) !important;
-moz-box-shadow: 3px 7px 21px -8px rgba(8,29,75,0.47) !important;
}

.card {
  border-radius: 20px;

  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  max-width: 545px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
cursor: pointer;
}
.card:hover > .container-card{
 /* background-color: #F6F6F6; */
}
.container-card {
  padding: 20px 16px;
}