.Nav-bar{
  display: flex;
  /* gap: 20px; */
  justify-content: space-between;
  height: 55px;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
}
.nav-bar-options{
  display: flex;
  text-decoration: none;
  gap: 10px;
}
.nav-bar-buttons{
  font-family: "Arial" !important;
  
}
.nav-bar-buttons:hover{
color: var(--alternative-color) !important;
}
