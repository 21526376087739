.main-footer {
  color: var(--white-color);
  background-color: var(--primary-color);
  padding-top: 3em;
  padding-bottom: 3em;
  position: relative;
  bottom: 0;
  width: 100%;
  font-family: "Arial";
}
.row-footer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap:40px;
}
.row-footer-content{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
  /* text-align: center; */
}
.row-footer-end{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
}
.col{
 margin-left: auto;
 margin-right: auto;

}
@media screen and (max-width: 647px) {
  .row-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap:40px;

  }
  .row-footer-content{
    /* text-align: center; */
  }
}