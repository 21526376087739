.navbar-component{
  background-color: var(--primary-color);
}

.navbar-default .navbar-nav>li>a{
  color: var(--white-color);
  padding: 10px;
  text-align: center;
  margin: 5px;
  
}

.navbar-default .navbar-nav>li>a:hover{
  color: var(--secondary-color);
  
}
